<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Contacto</h1>
          <p class="lead mt-4 italic">
              Ponte en contacto con Castor Web.
          </p>
      </div>
      
    </div>

    <div class="mt-5 bg-white p-5 animate__animated animate__fadeIn animate__faster">

        <p class="bold">Ventas</p>
        <p>
            Si tienes alguna duda acerca de algún producto de Castor Web que te interese adquirir, nos puedes
            contactar mandándonos un correo a <a href="mailto:ventas@castorweb.net">ventas@castorweb.net</a>
            desde la comodidad de tu manejador de correo preferido.
            <br>
            Favor de incluir en el correo la duda que tengas y con gusto de contestaremos tan pronto nos sea
            posible.
        </p>

        <p class="bold">Soporte</p>
        <p>
            Si tienes alguna duda referente a un producto adquirido en Castor Web, es decir, ya eres usuario de
            nosotros, favor de ir a la pestaña de <a routerLink="/soporte">soporte</a> y seguir las indicaciones.
        </p>

        <p>
            Cabe aclarar que Castor Web es una empresa de Desarrollo de Software y no nos dedicamos a la venta,
            renta y/o reparación o configuración de equipos de cómputo.
        </p>

    </div>

</div>
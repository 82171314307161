import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SimpleshopComponent } from './pages/simpleshop/simpleshop.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { SoporteComponent } from './pages/soporte/soporte.component';
import { SomosComponent } from './pages/somos/somos.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { GarantiaSamuraiComponent } from './pages/garantia-samurai/garantia-samurai.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { PmcComponent } from './pages/pmc/pmc.component';
import { PoliticaPrivacidadComponent } from './pages/politica-privacidad/politica-privacidad.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { LoginComponent } from './pages/login/login.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { ConfirmarCorreoComponent } from './pages/confirmar-correo/confirmar-correo.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    SimpleshopComponent,
    ProductosComponent,
    SoporteComponent,
    SomosComponent,
    ContactoComponent,
    InicioComponent,
    NoticiasComponent,
    GarantiaSamuraiComponent,
    ServiciosComponent,
    PmcComponent,
    PoliticaPrivacidadComponent,
    TerminosCondicionesComponent,
    LoginComponent,
    LoadingComponent,
    ConfirmarCorreoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

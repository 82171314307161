import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscripcionesService {

  constructor( private http: HttpClient ) { }

  solicitudSubscripcion( id: string, email: string, token: string ) {
    
    const url = `${ environment.SITE_API }/app/rutas/producto/solicitud.php`;
    
    const params = new HttpParams()
        .set('api_key', environment.API_KEY)
        .set('producto', id)
        .set('email', email)
        .set('token', token);

    return this.http.get( url, { params } )
      .pipe(
        map( resp => {
          return resp;
        })
    );

  }

}

<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Acerca de Castor Web</h1>
          <p class="lead mt-4 italic">
              Aquí no trabajan robots, aún.
          </p>
      </div>
      
  </div>

    <div class="mt-5 bg-white p-5 animate__animated animate__fadeIn animate__faster">

        <div class="row">

            <div class="col-md-3 col-sm-12 mx-auto">
                <img src="assets/img/omar_escudero.jpg" alt="Omar Escudero" width="200px" class="img-thumbnail">
            </div>

            <div class="col-md-9 col-sm-12">
                <p class="bold">
                    Omar Arturo
                    <br>
                    <span class="italic">
                        Programador y Fundador de Castor Web
                    </span>
                </p>
        
                <p>
                    Hola Mundo, soy Omar Arturo el Fundador de Castor Web. Soy Desarrollador de Software autodidácta
                    y Gamer apasionado. Me gusta la mejora constante y la atención a detalles.
                    Creé esta compañia con el propósito de poder brinder Software de calidad accesible para todos.
                </p>
            </div>

            <div class="mx-3">
                <p>
                    Mi fílosofía es la de facilitar el uso del Software al usuario y no complicarlo con interfaces
                    absurdas y saturadas. Todo Software que hago esta hecho desde cero y constantemento lo estoy
                    mejorando, por lo que la frase <span class="italic">"Si no esta roto no lo repares"</span> no me
                    aplica del todo puesto que siempre hay lugar para mejorar.
                </p>

                <p>
                    Todo producto y trabajo realizado por Castor Web cuenta con nuestra <a routerLink="/garantia-samurai">Garantía Samurái</a>.
                    Nos interesa mucho tu satisfacción y tu privacidad. Todo lo que pasé dentro de Castor Web se queda en Castor Web.
                </p>

                <p>
                    Espero que podamos trabajar juntos y apoyarte en tu negocio.
                </p>

                <div class="col">
                    <p class="mt-5 saint-andrews">
                        Omar Arturo
                    </p>
                </div>
                <div class="clearfix"></div>
            </div>
            
        </div>

    </div>

</div>
<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Noticias</h1>
          <p class="lead mt-4 italic">
              De Castor Web al mundo
          </p>
      </div>
      
  </div>

  <div class="mt-5 bg-white p-5 mx-3 animate__animated animate__fadeIn animate__faster">

    <ul class="nav nav-pills" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="latest-tab" data-toggle="tab" href="#latest" role="tab" aria-controls="latest" aria-selected="true">Lo Último</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="updates-tabs" data-toggle="tab" href="#updates" role="tab" aria-controls="updates" aria-selected="true">Actualizaciones</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="upcoming-tabs" data-toggle="tab" href="#upcoming" role="tab" aria-controls="upcoming" aria-selected="true">Próximamente</a>
        </li>
    </ul>

    <div class="tab-content" id="myTab">
        
        <div class="tab-pane fade show active" id="latest" role="tabpanel" aria-labelledby="latest-tab">
            <div class="mt-5">
                
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    
                    <div class="col">
                        <div class="card">
                            <img src="assets/img/simpleshop_product.jpg" class="card-img-top" alt="Simple Shop" width="290px">
                            <div class="card-body text-center">
                            <h5 class="card-title"><span class="badge badge-info">En Desarrollo</span></h5>
                            <p class="card-text">Versión 1.1</p>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <img src="assets/img/castorweb_hq.jpg" class="card-img-top" alt="Pomodoro Shop" width="290px">
                            <div class="card-body text-center">
                            <h5 class="card-title"><span class="badge badge-info">En Desarrollo</span></h5>
                            <p class="card-text">Plataforma para usuarios</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col">
                        <div class="card">
                            <img src="assets/img/pomodoroshop_product.jpg" class="card-img-top" alt="Pomodoro Shop" width="290px">
                            <div class="card-body text-center">
                            <h5 class="card-title"><span class="badge badge-info">En Desarrollo</span></h5>
                            <p class="card-text">Sistema Punto de Venta para Restaurantes</p>
                            </div>
                        </div>
                    </div>
                  </div>

            </div>
        </div>

        <div class="tab-pane fade" id="updates" role="tabpanel" aria-labelledby="updates-tab">
            <div class="card-body">
                
                <!-- Simple Shop v1.1 -->
                <p class="bold">
                    Simple Shop v1.1
                </p>
                <p>
                    Nos encontramos trabajando en la versión 1.1 de Simple Shop. En esta versión agregaremos lo siguiente:
                </p>
            
                <ul class="px-5">
                    <li class="pb-2">
                        <span class="text-600">POS</span>: podrán realizar descuentos en el Punto de Venta para todos aquellos
                        usuarios que tengan privilegio para descuentos.
                        <br>
                        También existirá la opción de poder mandar el ticket al cliente por correo;
                        esto es con el objetivo principal de disminuir la contamicación global.
                    </li>
                    <li class="pb-2">
                        <span class="text-600">Inventario</span>: se podrá definir las cantidades máximas y mínimas a tener en stock de tus
                        productos. El sistema mandará correo al Super Usuario (cliente que adquirio el producto)
                        sobre los productos que se encuentran bajos en Stock.
                    </li>
                    <li class="pb-2">
                        <span class="text-600">Notificaciones</span>: se agregará un sistema de notificaciones en donde podrás ver los cambios
                        realizados al Sistema por parte nuestra.
                    </li>
                    <li class="pb-2">
                        <span class="text-600">Configuración</span>: poder definir la zona horaria del sistema.
                        <br>
                        También tendrán la opción de habilitar el reseteo de No de Ventas del Sistema
                        cada inicio de mes.
                    </li>
                    <li class="pb-2">
                        <span class="text-600">Reportes</span>: se agregarán algunos nuevos reportes de ventas.
                    </li>
                    <li class="pb-2">
                        <span class="text-600">Tareas</span>: se harán varios cambios al módulo de tareas pudiendo agregar nivel de dificultad
                        en las tareas y varios detalles más. También se crearán reportes de tareas.
                    </li>
                    <li class="pb-2">
                        Algunas optimizaciones y correcciones de bugs que se hayan presentado.
                    </li>
                </ul>

                <p>
                    Esta versión será gratis para todos los usuarios de Simple Shop y se estima que estará
                    disponible en Diciembre 2020.
                </p>                

            </div>
        </div>

        <div class="tab-pane fade" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
            <div class="card-body">
                
                <!-- HQ -->
                <p class="bold">
                    Plataforma para usuarios de Castor Web
                </p>
                <p>
                    Nos encontramos trabajando en una plataforma en la cúal podrás registrarte y seleccionar desde
                    ahí tus productos. También podrás realizar el pago de tus productos, solicitar soporte, ver guías
                    y tutoriales para configuraciones de tus equipos de cómputo y mucho más.
                </p>

                <p>
                    Esta plataforma se estima que estará disponible a principios de 2021.
                </p>

                <!-- Pomodoro Shop -->
                <p class="bold">
                    Pomodoro Shop
                    <br>
                    Sistema Punto de Venta para Restaurantes
                </p>
                <p>
                    Así como Simple Shop es un Punto de Venta para Tiendas, con Pomodoro Shop podrás administrar tu
                    restaurante de forma amigable y eficiente.
                </p>

                <p>
                    Esta producto se estima que estará disponible el primer cuarto de 2021.
                </p>

            </div>
        </div>

    </div>

  </div>

</div>
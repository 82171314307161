<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Políticas de Privacidad</h1>
          <p class="lead mt-4 italic">
              Tus información está segura.
          </p>
      </div>
      
  </div>

  <div class="mt-5 bg-white p-5 mx-3 animate__animated animate__fadeIn animate__faster">

    <p>
        Esta va a ser la pagina con menor contenido.
    </p>

    <p>
        Castor Web nunca renta, presta o vende correos electrónicos o cualquier otro tipo de información de nuestros usuarios
        a ninguna persona o compañia. Valoramos demasiado la privacidad de nuestros usuarios y por lo mismo no nos prestamos
        en dar a conocer tu información.
    </p>

    <img src="assets/img/thatsallfolks.jpg" alt="Eso es todo amigos" width="400px" class="d-block mx-auto img-thumbnail">

  </div>

</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SimpleshopComponent } from './pages/simpleshop/simpleshop.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { SomosComponent } from './pages/somos/somos.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { SoporteComponent } from './pages/soporte/soporte.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { GarantiaSamuraiComponent } from './pages/garantia-samurai/garantia-samurai.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { PmcComponent } from './pages/pmc/pmc.component';
import { PoliticaPrivacidadComponent } from './pages/politica-privacidad/politica-privacidad.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { LoginComponent } from './pages/login/login.component';
import { ConfirmarCorreoComponent } from './pages/confirmar-correo/confirmar-correo.component';


const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'simpleshop', component: SimpleshopComponent },
  { path: 'noticias', component: NoticiasComponent },
  { path: 'productos', component: ProductosComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'soporte', component: SoporteComponent },
  { path: 'pmc', component: PmcComponent },
  { path: 'politica-de-privacidad', component: PoliticaPrivacidadComponent },
  { path: 'terminos-y-condiciones', component: TerminosCondicionesComponent },
  { path: 'somos', component: SomosComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'garantia-samurai', component: GarantiaSamuraiComponent },
  { path: 'login', component: LoginComponent },
  { path: 'confirmar-correo/:email/:codigo', component: ConfirmarCorreoComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">PMC</h1>
          <p class="lead mt-4 italic">
              Respuesta a las preguntas más comunes
          </p>
      </div>
      
  </div>

    <div class="mt-5 bg-white p-5 mx-3 animate__animated animate__fadeIn animate__faster">

        <p class="bold">Sobre las formas de pago.</p>
        <p>
            Actualmente contamos únicamente con depósitos a cuenta bancaria. Estamos trabajando en una plataforma
            donde te podrás registrar como usuario y desde ahí realizar tus pagos en línea, de manera que no tendrás
            que salir de tu casa para realizar tu pago.
        </p>

        <p class="bold">¿Qué nuevos productos vienen en camino?</p>
        <p>
            Buena pregunta, en Castor Web nos encontramos mejorando nuestros productos constantemente, sin embargo
            estamos trabajando en un Punto de Venta para restaurantes llamado Pomodoro Shop. Después de finalizar el
            desarrollo de Pomodoro Shop haremos una pequeña encuesta a nuestros usuarios para saber que nuevo producto
            les gustaría que realicemos a continuación.
            <br>
            Puedes ver más información sobre actualizaciones de nuestros productos actuales al igual que el desarrollo
            de nuevos proyectos en la pestaña de Noticias.
        </p>

        <p class="bold">No recuerdo mis datos de acceso, ¿como le hago?</p>
        <p>
            No te preocupes esto nos pasa a todos. En lo que terminamos la plataforma para usuarios donde podrás recuperar
            tu contraseña proporcionando tu correo de usuario, mientras nos puedes mandar un correo a
            <a href="mailto:soporte@castorweb.net">soporte@castorweb.net</a> indicandonos que olvidaste tu contraseña.
            Es importante que nos mandes el correo desde el correo del usuario, esto es con el fin de poder identificar
            que eres el propietario del correo y por ende el usuario.
        </p>

        <p class="bold">¿Cómo funciona el soporte?</p>
        <p>
            Para cuando tengas algún problema con tu producto, actualmente contamos únicamente con soporte por correo. Tenemos
            en nuestros planes agregar una plataforma de chat para el 2021, esta será añadida en la plataforma para usuarios.
            <br>
            Cabe mencionar que nuestro soporte es únicamente de nuestros productos, es decir el Software en sí, no damos soporte
            a cuestiones de hardware. Sin embargo vamos a agregar próximamente guías y tutoriales sobre las dudas y problemas
            más comunes que se te pueden presentar con tus equipos de cómputo. Esto es para poder apoyarte lo más que se pueda en
            tu negocio.
            <br>
            Sin embargo, Castor Web es y será siempre una empresa de Desarrollo de Software.
        </p>

        <p class="bold">Necesito realizar cambios a uno de mis productos, ¿como le hago?</p>
        <p>
            Si te refieres a realizar cambios al código fuente de un producto para adaptarlo a las necesidades de tu negocio o si
            necesitas que te desarrollemos un software que no lo tenemos, claro que te podemos ayudar. Para más información visita
            la pestaña de <a routerLink="/servicios">Servicios</a>.
        </p>

        <p class="bold">¿De dónde es Castor Web?</p>
        <p>
            En Castor Web somos ciudadanos del mundo, pero tenemos nuestra sede en Tijuana B.C. México.
        </p>

    </div>

</div>
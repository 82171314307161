import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userToken: string;

  constructor( private http: HttpClient ) {
    this.leerToken();
  }

  login( email: string, password: string ) {

    const url = `${ environment.SITE_API }/app/rutas/usuario/login.php`;

    const params = new HttpParams()
      .set('api_key', environment.API_KEY)
      .set('email', email)
      .set('password', password);
    
    return this.http.get( url, { params } )
      .pipe(
        map( resp => {
          
          if ( resp['token'] ) {
            this.guardarToken( resp['token'], resp['nombre'], email );
          }

          return resp;

        })
    );
    
  }

  recuperarPassword( email: string ) {

    const url = `${ environment.SITE_API }/app/rutas/usuario/recuperar-password.php`;

    const params = new HttpParams()
      .set('api_key', environment.API_KEY)
      .set('email', email);
    
    return this.http.get( url, { params } )
      .pipe(
        map( resp => {
          return resp;
        })
    );
    
  }

  registro( nombre: string, email: string, password: string ) {

    const url = `${ environment.SITE_API }/app/rutas/usuario/registro.php`;

    const params = new HttpParams()
      .set('api_key', environment.API_KEY)
      .set('nombre', nombre)
      .set('email', email)
      .set('password', password);
    
    return this.http.get( url, { params } )
      .pipe(
        map( resp => {

          if ( resp['token'] ) {
            this.guardarToken( resp['token'], resp['nombre'], email );
          }
          
          return resp;

        })
    );
    
  }

  private guardarToken( idToken: string, nombre: string, email: string ) {
  
    this.userToken = idToken;
    
    localStorage.setItem('token', idToken);
    localStorage.setItem('username', nombre);
    localStorage.setItem('email', email);
  
    let hoy = new Date();
    hoy.setSeconds( 86400 * 7 );

    localStorage.setItem('expira', hoy.getTime().toString() );

  }

  leerToken() {

    if ( localStorage.getItem('token') ) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }

    return this.userToken;

  }
  
  estaAutenticado(): boolean {

    if ( this.userToken.length < 2 ) {
      return false;
    }

    const expira = Number( localStorage.getItem('expira') );
    const expiraDate = new Date();
    expiraDate.setTime(expira);

    if ( expiraDate > new Date() ) {
      return true;
    } else {

      localStorage.clear();
      return false;

    }

  }

}

<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Soporte</h1>
          <p class="lead mt-4 italic">
              ¡Houston tenemos un problema!
          </p>
      </div>
      
  </div>

  <div class="animate__animated animate__fadeIn animate__faster">
    
    <div class="mt-5 mx-3">

        <h4 class="quote">
            Los bugs suelen suceder en todo Software,
            <br>
            la atención y disposición para solucionarlos marcan la diferencia.
        </h4>

        <p class="text-right italic mr-5 mt-4">
            - Un Castor
        </p>

    </div>

    <div class="mt-5 bg-white p-5 mx-3">

        <p>
            Si has tenido algun problema con tu producto el cual hace algo que no debe de hacer o si no tienes acceso a el,
            no te preocupes, estamos aquí para apoyarte.
        </p>

        <p>
            Sin embargo, para poder brindarte un servicio adecuado, es necesario que pongas de tu parte siguiendo las
            siguientes recomendaciones:
        </p>

        <h3 class="bold mt-4 mb-4 django">Solución de problemas</h3>

        <p>
            Primero debes de llevar a cabo esta sección de solución de problemas de acuerdo al problema que estes presentando.
        </p>

        <p class="bold mt-4">Problemas para ingresar al sitio del producto</p>

        <ul class="px-5">
            <li>
                Asegúrate de tener conexión a Internet y que está sea estable. Para eso intenta abrir algún sitio web como YouTube.
                O si estas viendo esta pagina mediante la computadora en la que tienes el problema ya puedes descartar que no tengas
                Internet.
            </li>
            <li>
                Si tienes conexión a Internet pero aún asi no puedes acceder al sistema, puede que tengas algún problema en los caches
                de tu computadora o de tu módem. Intenta resetear tu módem o router presionando un botón pequeño que usualmente
                vienen en la parte trasera de tu módem o router. Para presionar este botón ocupas algo pequeño como un alfiler o un
                clip. Cuando vayas a presionar el botón lo debes de mantener presionado por aproximadamente 10 segundos hasta que
                notes como las luces se van apagando. Una vez realizado este paso es cuestión que te esperes unos 3-4 minutos en lo
                que tu módem o router se restablece. Cabe mencionar que cualquier configuración que hallas tenido en tu módem o router
                ( como el nombre de la red ) se perderá pero podrás configurarlo de nuevo. A continuación te muestro una imagén para
                tu referencia.
                <img src="assets/img/modem_reset.png" alt="Módem" width="50%" class="d-block mx-auto">
            </li>
            <li>
                Reinicia tu dispositivo.
            </li>
        </ul>

        <p class="bold mt-4">Problemas para ingresar al producto</p>
        <p>
            Similar al punto anterior pero con la diferencia de que aquí si puedes ver el sitio del producto pero no puedes acceder a él.
            Para eso has lo siguiente:
        </p>

        <ul>
            <li>
                Asegúrate de ingresar bien los datos de usuario y contraseña del sistema. Cabe mencionar que existen los datos de cliente
                y los datos de usuario de tu producto. Esos datos se especifícan en los campos de usuario. Si no recuerdas tus datos de
                usuario pídeselos a tu administrador. Si no recuerdas tus datos de Cliente, puedes revisar el correo que te llego cuando
                adquiriste el producto. Si no tienes acceso a ese correo, mándanos un correo a <a href="mailto:soporte@castorweb.net">soporte@castorweb.net</a>
                desde el correo que se utilizó para adquirir el producto ( esto es para poder verificar que eres el propietario del producto ).
            </li>
            <li>
                Asegúrate de haber realizado el pago de tu producto :)
            </li>
        </ul>

        <p class="bold mt-4">El producto hace cosas raras</p>
        <p>
            Si tu producto hace cosas raras como calcular mal una operación o cosas por el estilo, mándanos un correo a
            <a href="mailto:soporte@castorweb.net">soporte@castorweb.net</a> y <b>POR FAVOR</b> sé lo más específico posible al explicar
            la falla. Intenta poner capturas de pantalla y todo lo que creas que nos pueda ayudar a entender el problema. Para poder
            solucionar el problema debemos de poder reproducir el problema desde nuestro lado por lo que son necesarios los detallitos.
        </p>

        <p class="bold mt-4">Fallas en tus equipos o impresoras</p>
        <p>
            Lamentablemente las fallas que se puedan presentar en tus equipos como computadoras, impresoras, lectores de códigos de barras
            o cualquier otro dispositivo que utilices, te corresponde a ti solucionarlo. En Castor Web solo hacemos Desarrollo de Software
            y no nos dedicamos a la venta, reparación o configuración de hardware.
        </p>
        <p >
            A pesar de lo anterior mencionado, en Castor Web nos interesa que puedas trabajar con tu producto, por lo que estaremos haciendo
            tutoriales y guías sobro como solucionar los problemas más comunes que se te puedan presentar con tus equipos.
        </p>
        
        <h3 class="bold mt-4 mb-4 django">Como mandar correos</h3>
        <p>
            Para cuando te vayas a comunicar con nosotros mediante correo, por favor sigue las siguientes recomendaciones para así
            poder ayudarte lo más rápido posible.
        </p>

        <ul class="px-5 text-justify">
            <li>
                Incluye en el Asunto del correo el nombre del producto en parentesís cuadrados seguido del nombre del cliente. Ejemplo:
                <br>
                <b>Asunto:</b> [Simple Shop] Juan Pérez
                <br>
                <b>Mensaje:</b> La descripción del problema
            </li>
        </ul>

        <p>
            Actualmente estamos trabajando en una plataforma para clientes en donde podrán ver los productos que tienen adquiridos, así
            como realizar pagos y también hacer solicitudes de soporte.
        </p>

    </div>

  </div>

</div>
<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Términos y Condiciones de Uso</h1>
          <p class="lead mt-4 italic">
              Información importante de todos los productos de Castor Web
          </p>
      </div>
      
  </div>

  <div class="mt-5 bg-white p-5 mx-3 animate__animated animate__fadeIn animate__faster">

    <p>
        Por favor lee con atención los siguientes Términos y Condiciones de uso que aplica para
        todos los productos de Castor Web.
    </p>

    <p class="bold">1. Condiciones básicas.</p>
    <p>
        El usuario es responsable del uso que haga con los productos adquiridos en Castor Web.
        <br>
        El usuario debe respetar en todo momento los presentes Términos y Condiciones de Uso del
        Software. De forma expresa el usuario manifiesta que utilizará el Software de forma diligente
        y asumiendo cualquier responsabilidad que pudiera derivarse del incumplimiento de las normas.
    </p>

    <p class="bold">2. Derechos de Autor.</p>
    <p>
        Los productos de Castor Web se ponen a disposición para su uso exclusivamente para los usuarios
        de conformidad con lo estipulado en los Términos y Condiciones de Uso. El uso del Software es
        únicamente una Licencia para poder utilizarlo mientras se este cubriendo el costo del Software
        y estes cumpliendo con los Términos y Condiciones de Uso del Software.
        El Software es propiedad intelectual única y exclusivamente de Castor Web.
    </p>
    <p>
        En caso de que el usuario no cumpla con los presentes Términos y Condiciones de Uso, Castor Web
        se reserva el derecho de poder cancelar el acceso del usuario para sus productos, al igual que
        poder bloquear e incluso eliminar al usuario para siempre.
    </p>

    <p class="bold">3. Limitaciones de garantía y responsabilidad.</p>
    <p>
        Este apartado establece los límites de responsabilidad de Castor Web y de sus programadores y cualquier
        integrante de Castor Web. El usuario es responsable del uso que haga de los Productos y los accesos a
        estos. Usted entiende y acepta que los Servicios se le proporcionan COMO ESTÁN Y SEGÚN DISPONIBILIDAD.
        <br>
        Intentamos mantener los productos de Castor Web en funcionamiento, sin errores y seguro, pero usted lo
        utiliza bajo su propia responsabilidad.
    </p>
    <p>Proporcionamos los productos de Castor Web tal cual, sin garantía alguna expresa o implícita,
        incluidas, entre otras, las garantías de comerciabilidad, adecuación a un fin particular y no incumplimiento. No
        garantizamos que nuestros productos sean siempre seguros o estén libre de errores, ni que funcionen siempre
        sin interrupciones, retrasos o imperfecciones. Castor Web no se responsabiliza de las acciones, el contenido,
        la información o los datos de terceros, y por la presente usted dispensa a la empresa, nuestros programadores
        y cualquier integrante de Castor Web.
    </p>

    <p class="bold">4. Pagos y Reembolsos.</p>
    <p>
        Se debe de realizar el pago de tus productos dentro de los primeros 5 días de la fecha correspondiente según el usuario.
        En caso de no realizar el pago a tiempo, se incrementará el costo 10% por día hasta que se realice el pago.
        <br>
        Después de 15 días de no haber realizado el pago se suspenderá el acceso a tu producto pendiente de pago hasta que se
        haya cubierto el pago.
    </p>
    <p>
        Si tienes algún producto suspendido por falta de pago por mas de 30 días, se te congelara el producto por hasta 3 meses
        antes de eliminarlo por completo de nuestros servidores y pierdas toda la información que tengas en ese producto.
        Si posteriormente quieres adquirir el producto debes de pagar primero lo que debas más un costo adicional de reconfiguración.
    </p>
    <p>
        Castor Web no tiene la obligación de proporcionar reembolsos de ningún tipo para cualquiera de nuestros productos.
        Usted es responsable de elegir el producto que más se adecúe a sus necesidades.
    </p>
    <p>
        Cualquier trabajo realizado a los productos de Castor Web o al Software hecho a la medida según las necesidades
        del usuario, se deberá de cubrir el costo total por adelantado. El trabajo se entregará según lo acordado y si el
        usuario posteriormente decide hacer cambios, estos tendrán costo adicional.
    </p>

    <p class="bold">5. Alcance de los productos de Castor Web.</p>
    <p>
        Nuestra responsabilidad es el proporcionarte acceso a los productos que hayas adquirido en Castor Web, sin embargo
        Castor Web no tiene la responsabilidad de configurar equipos de cómputo ni interactuar con los productos adquiridos
        por el usuario como el hacer operaciones dentro de ellos a petición del usuario.
    </p>

    <p class="bold">6. Soporte.</p>
    <p>
        Para cuando se llegue a presentar alguna falla en los productos de Castor Web, el soporte es únicamente por correo
        electrónico. El usuario deberá de revisar la sección de soporte primero y seguir los pasos antes de solicitar soporte.
        Si el usuario ya realizo los pasos y aún así no se resuelve la falla, con mucho gusto te podemos ayudar. Favor de seguir
        las indicaciones de la pestaña de Soporte. Castor Web te responderá en el menor tiempo que nos sea posible.
    </p>

  </div>

</div>
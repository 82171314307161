import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  token    = '';
  username = '';
  email    = '';

  constructor( private authService: AuthService ) { }

  ngOnInit(): void {

    if ( typeof(Storage) !== 'undefined' ) {
      
      if ( this.authService.estaAutenticado() ) {

        this.token    = localStorage.getItem('token');
        this.username = localStorage.getItem('username');
        this.email    = localStorage.getItem('email');

      }

    } else {
     
      Swal.fire({
        icon: 'error',
        title: 'Navegador Obsoleto',
        text: 'Debes utilizar un navegador moderno para poder utilizar nuestro sitio apropiadamente',
        showConfirmButton: false
      });

    }

  }

  logOut() {
    
    localStorage.clear();
    window.location.reload();

  }

}

<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3 animate__animated animate__fadeIn animate__faster">
        <img src="favicon.ico" alt="Castor" class="d-block mx-auto pt-5 pb-5">

        <div class="mt-4 text-center">
            <h1 class="display-4">¡Hola Castores!</h1>
            <p class="lead mt-4">Bienvenido a este sitio donde encontrarás soluciones informáticas para tu negocio.</p>

            <hr class="my-4">
            <p>
                De momento solo contamos con nuestro nuevo producto Simple Shop v1.0 con el cual
                <br>
                puedes administrar tu negocio con este maravilloso Sistema Punto de Venta.
                <br>
                Estamos trabajando en más soluciones para tu negocio.
            </p>
            <div class="d-grid d-md-block">
                <a class="btn btn-orange btn-lg mt-5 mb-5" routerLink="/productos" role="button">Ver productos</a>
            </div>
        </div>
        
    </div>

    <div class="bg-white mx-3 mt-5 p-5 animate__animated animate__fadeIn animate__faster">

        <h3>
            Nuevos Usuarios
        </h3>
        <p class="mt-4">
            Para que puedas hacer uso de nuestros productos, es necesario que primero te registres como
            usuario. No te preocupes, tus datos son privados y solo se utilizarán dentro de esta plataforma.
        </p>

        <div class="d-grid d-md-block">
            <button class="btn btn-outline-orange mt-5 btn-lg" routerLink="/login">
                Iniciar Sesión / Registrarme
            </button>
        </div>

    </div>

</div>
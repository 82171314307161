import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  anio = new Date().getFullYear();
  ruta: string;

  constructor( private router: Router ) { }

  ngOnInit(): void {

    // Ruta actual
    this.router.events.subscribe(val => {

      if (val instanceof NavigationEnd) {

        this.ruta = val.url.replace('/', '');
        this.ruta = this.ruta.replace(/-/g, ' ');
        
        if ( this.ruta.includes('/') ) {
          const index = this.ruta.indexOf('/');
          this.ruta = this.ruta.slice( 0, index );
        }
        
        if ( this.ruta === '' ) {
          this.ruta = 'Inicio';
        }

      }

    });

  }

}

<nav class="navbar navbar-dark navbar-expand-lg mb-5">
    <div class="container-fluid navbar_wrapper mx-auto">

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto px-5">
                <li class="nav-item">
                    <a class="navbar-brand" routerLinkActive="active" routerLink="">
                        <img src="favicon.ico" alt="Inicio" width="32" class="pt-2">
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="simpleshop">Simple Shop <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="noticias">Noticias</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="productos">Productos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="servicios">Servicios</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="soporte">Soporte</a>
                </li>
            </ul>
        </div>
        
    </div>
</nav>

<div class="wrap-content usuario-login" *ngIf="token">
    <div class="mx-3 float-right">
        <p class="">
            Hola {{ username }} | <a class="pointer no-underline purple" (click)="logOut()">Cerrar Sesión</a>
        </p>
    </div>
    <div class="clearfix"></div>
</div>
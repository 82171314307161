<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Servicios</h1>
          <p class="lead mt-4 italic">
              ¿Qué más podemos hacer por tí?
          </p>
      </div>
      
  </div>

  <div class="mt-5 bg-white p-5 mx-3 animate__animated animate__fadeIn animate__faster">

    <p>
        Si necesitas algún trabajo en particular para algunos de tus productos adquiridos en
        Castor Web, o si ocupas que te desarrollemos algun Software específico para tu negocio, nosotros te podemos ayudar.
    </p>

    <p>
        Por favor comunicate con nosotros a <a href="mailto:ventas@castorweb.net">ventas@castorweb.net</a> indicando el motivo de tu
        solicitud. Un miembro de nuestro equipo revisará tu solicitud y se comunicará contigo.
    </p>

    <p>
        Cabe aclarar que la disponibilidad para realizar tu petición se verá afectada de acuerdo a la carga de trabajo que tengamos.
        En Castor Web nos encontramos mejorando nuestros productos constantemente y también estamos creando nuevos productos para
        diversos giros de negocio. Nosotros seremos honestos contigo si acaso podemos trabajar juntos en un tiempo razonable de manera
        que no te hagamos perder tu tiempo tratando de hacer tu proyecto si sabemos que no vamos a cumplir con la fecha estimada.
    </p>

    <p>
        Todo nuestro trabajo esta hecho a la medida de tus necesidades y cuenta con nuestra <a routerLink="/garantia-samurai">Garantía Samurái</a>.
    </p>

  </div>

</div>
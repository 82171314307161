<div class="wrap-content mx-auto mb-5">

  <div class="jumbotron mx-3">

        <div class="text-center pt-5 pb-5">
            <h1 class="display-4">Simple Shop</h1>
            <p class="lead mt-4 italic">
                Tu negocio administrado sin esfuerzo
            </p>
        </div>
        
    </div>

    <div class="animate__animated animate__fadeIn animate__faster">

      <p class="mt-4 mb-5 mx-3 bg-white p-5">
          Sistema Punto de Venta para Tiendas. Con Simple Shop podrás hacer toma de pedidos,
          control de inventarios, retiros, cortes, reportes y más. Eligé la versión que se
          adecúe a tus necesidades.
      </p>
      
      <div class="card-group">
          
          <div class="card mx-3">
            <img src="assets/img/simpleshop_basico.jpg" class="card-img-top" alt="Básico">
            <div class="card-body text-center">
              <h5 class="card-title">
                <span class="badge badge-pill bg-secondary">Básico</span>
              </h5>
              <p class="card-text">
                  Todo lo que necesitas para empezar a administrar tu negocio. Ideal para negocios pequeños.
              </p>
              <ul class="list-group list-group-flush text-center">
                  <li class="list-group-item">
                    <i class="fas fa-database pr-2 django"></i> 512MB de almacenamiento
                  </li>
                  <li class="list-group-item">Ventas ilimitadas</li>
                  <li class="list-group-item">
                      $229 MXN <span class="badge bg-info">Mensuales</span>
                      <br>
                      <span class="small font-italic">1 sola tienda</span>
                  </li>
              </ul>
            </div>
            <div class="card-body mx-auto">
              <a class="btn btn-outline-django" (click)="subscribirme('1', 'Básico')">Subscribirme</a>
            </div>
          </div>

          <div class="card mx-3">
            <img src="assets/img/simpleshop_plus.jpg" class="card-img-top" alt="Plus">
            <div class="card-body text-center">
              <h5 class="card-title">
                <span class="badge badge-pill bg-success">Plus</span>
              </h5>
              <p class="card-text">
                  Ideal para negocios con más de una sucursal. En esta versión puedes hacer uso de múltiples tiendas
                  y administrarlas desde 1 solo lugar.
              </p>
              <ul class="list-group list-group-flush text-center">
                  <li class="list-group-item">
                    <i class="fas fa-database pr-2 django"></i>1GB de almacenamiento
                  </li>
                  <li class="list-group-item">Ventas ilimitadas</li>
                  <li class="list-group-item">
                      $289 MXN <span class="badge bg-info">Mensuales</span>
                      <br>
                      <span class="small font-italic">por tienda</span>
                  </li>
              </ul>
            </div>
            <div class="card-body mx-auto">
              <a class="btn btn-outline-django" (click)="subscribirme('2', 'Plus')">Subscribirme</a>
            </div>
          </div>

          <div class="card mx-3">
            <img src="assets/img/simpleshop_premium.jpg" class="card-img-top" alt="Premium">
            <div class="card-body text-center">
              <h5 class="card-title">
                <span class="badge badge-pill bg-warning">Premium</span>
              </h5>
              <p class="card-text">
                Ideal para negocios con muchas sucursales y su propio comisariato. Incrementa tu almacenamiento al máximo para todas tus tiendas.
              </p>
              <ul class="list-group list-group-flush text-center">
                  <li class="list-group-item">
                    <i class="fas fa-database pr-2 django"></i> 2GB de almacenamiento
                  </li>
                  <li class="list-group-item">Ventas ilimitadas</li>
                  <li class="list-group-item">
                      $339 MXN <span class="badge bg-info">Mensuales</span>
                      <br>
                      <span class="small font-italic">por tienda</span>
                  </li>
              </ul>
            </div>
            <div class="card-body mx-auto">
              <a class="btn btn-outline-django" (click)="subscribirme('3','Premium')">Subscribirme</a>
            </div>
          </div>

      </div>

      <div class="mt-5 px-3">

        <h3 class="django">Lista de especificaciones del sistema</h3>
        <div class="table-responsibe-md">
          <table class="table mt-4 bg-white tabla_especificaciones">
            <thead class="bg-django">
              <tr>
                <th scope="col"></th>
                <th scope="col" class="text-center">Básico</th>
                <th scope="col" class="text-center">Plus</th>
                <th scope="col" class="text-center">Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Almacenamiento</th>
                <td>512 MB</td>
                <td >1 GB</td>
                <td>2 GB</td>
              </tr>
              <tr>
                <th scope="row">Ventas</th>
                <td>Ilimitadas</td>
                <td>Ilimitadas</td>
                <td>Ilimitadas</td>
              </tr>
              <tr>
                <th scope="row">POS *</th>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
              </tr>
              <tr>
                <th scope="row">Reportes *</th>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
              </tr>
              <tr>
                <th scope="row">Inventario *</th>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
              </tr>
              <tr>
                <th scope="row">Tareas *</th>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
              </tr>
              <tr>
                <th scope="row">Administrador *</th>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
              </tr>
              <!-- <tr>
                <th scope="row">
                  Requisiciones * <span class="badge badge-info">en desarrollo</span>
                </th>
                <td></td>
                <td></td>
                <td><i class="fas fa-circle"></i></td>
              </tr> -->
              <!-- <tr>
                <th scope="row">
                  Proyectado de Ventas * <span class="badge badge-info">en desarrollo</span>
                </th>
                <td></td>
                <td></td>
                <td><i class="fas fa-circle"></i></td>
              </tr> -->
              <tr>
                <th scope="row">Múltiples Sucursales</th>
                <td></td>
                <td><i class="fas fa-circle django"></i></td>
                <td><i class="fas fa-circle django"></i></td>
              </tr>
              <tr>
                <th scope="row">Cambios Especificos **</th>
                <td></td>
                <td></td>
                <td><i class="fas fa-circle django"></i></td>
              </tr>
              <!-- <tr>
                <th scope="row">
                  Apps iOS y Android <span class="badge badge-info">en desarrollo</span>
                </th>
                <td></td>
                <td></td>
                <td><i class="fas fa-circle"></i></td>
              </tr> -->
            </tbody>
          </table>
        </div>

        <span class="small">* módulos del sistema</span>
        <br>
        <span class="small">** habilidad de poder solicitar cambios en el código fuente para adaptarlo a las necesidades de tu negocio (se cobrarán horas de trabajo)</span>

        <div class="mt-5">
          <h3 class="django">
            Módulos del Sistema
          </h3>

          <div class="card text-center mt-4">
            <div class="card-header bg-django">
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#pos" role="tab" aria-controls="pos" aria-selected="true">POS</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#reports" role="tab" aria-controls="reports" aria-selected="false">Reportes</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#inventory" role="tab" aria-controls="inventory" aria-selected="false">Inventario</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#task" role="tab" aria-controls="task" aria-selected="false">Tareas</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#admin" role="tab" aria-controls="admin" aria-selected="false">Administrador</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#requisitions" role="tab" aria-controls="requisitions" aria-selected="false">Requisiciones</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#saleobjective" role="tab" aria-controls="saleobjective" aria-selected="false">Proyectado de Ventas</a>
                </li>
              </ul>
            </div>
            <div class="tab-content pt-5" id="myTabContent">
              
              <div class="tab-pane fade show active" id="pos" role="tabpanel" aria-labelledby="pos-tab">
                <div class="card-body">
                  <img src="assets/img/pos.png" alt="POS">
                  <h5 class="card-title mt-3 bold">Punto de Venta <i>(Point of Sale)</i></h5>
                  <p class="card-text">
                    Toma de ventas donde podras realizar retiros, cortes de caja, impresiones de tickets, cancelaciones y más.
                  </p>
                  <p>Disponible en todas las versiones</p>
                </div>
              </div>
              
              <div class="tab-pane fade" id="reports" role="tabpanel" aria-labelledby="reports-tab">
                <div class="card-body">
                  <img src="assets/img/reports.png" alt="Reportes">
                  <h5 class="card-title mt-3 bold">Reportes</h5>
                  <p class="card-text">Reportes de cortes, retiros, ventas diarias, ventas por producto, comisiones y mucho más.</p>
                  <p>Disponible en todas las versiones</p>
                </div>
              </div>

              <div class="tab-pane fade" id="inventory" role="tabpanel" aria-labelledby="inventory-tab">
                <div class="card-body">
                  <img src="assets/img/inventory.png" alt="Inventario">
                  <h5 class="card-title mt-3 bold">Inventario</h5>
                  <p class="card-text">Control de inventario con inventario inicial, compras, transferencias, inventario teórico, inventario final y diferencia.</p>
                  <p>Disponible en todas las versiones</p>
                </div>
              </div>

              <div class="tab-pane fade" id="task" role="tabpanel" aria-labelledby="task-tab">
                <div class="card-body">
                  <img src="assets/img/todolist.png" alt="Tareas">
                  <h5 class="card-title mt-3 bold">Tareas</h5>
                  <p class="card-text">Asigna tareas a tus usuarios para que mantengas organizado los deberes desde el sistema.</p>
                  <p>Disponible en todas las versiones</p>
                </div>
              </div>

              <div class="tab-pane fade" id="admin" role="tabpanel" aria-labelledby="reports-tab">
                <div class="card-body">
                  <img src="assets/img/administrator.png" alt="Administrador">
                  <h5 class="card-title mt-3 bold">Administrador</h5>
                  <p class="card-text">Alta, bajas y cambios de usuarios, categorías, productos y la configuración del sistema.</p>
                  <p>Disponible en todas las versiones</p>
                </div>
              </div>

              <div class="tab-pane fade" id="requisitions" role="tabpanel" aria-labelledby="requisitions-tab">
                <div class="card-body">
                  <!-- <img src="assets/img/administrator.png" alt="Administrador"> -->
                  <h5 class="card-title mt-3 bold">Requisiciones <br><span class="badge bg-info mt-2">Próximamente 2021</span></h5>
                  <p class="card-text">Poder controlar las requisiciones de todas tus tiendas desde un solo lugar para que puedas
                    abastecer tus tiendas con facilidad.</p>
                  <p>Disponible solo en la versión Premium</p>
                </div>
              </div>

              <div class="tab-pane fade" id="saleobjective" role="tabpanel" aria-labelledby="saleobjective-tab">
                <div class="card-body">
                  <!-- <img src="assets/img/administrator.png" alt="Administrador"> -->
                  <h5 class="card-title mt-3 bold">Proyectado de Ventas <br><span class="badge bg-info mt-2">Próximamente 2021</span></h5>
                  <p class="card-text">Basado en tus ventas, podrás hacer proyectado de ventas para que puedas saber los productos
                    que tienes que adquirir con mayor precisión.</p>
                  <p>Disponible solo en la versión Premium</p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="mt-5 bg-white p-5">
          <h3 class="django">
            Requerimientos de Hardware
          </h3>
          <p class="mt-3">
            Siendo esta una Aplicación Web, no es necesario que tengas una super computadora; tampoco uses la computadora de tus abuelos. Cualquier computadora
            fabricada en los últimos 5 años debe de ser suficiente. También puedes utilizar una Tablet moderna e incluso un Smartphone. Cable aclarar que por
            facilitar la navegación en los menus, hacer uso de impresiones y demás, siempre va a ser mejor que utilices una computadora de escritorio puesto
            que tendrías una pantalla más grande, un teclado físico y la facilidad de utilizar una gran cantidad de impresoras ya que no todas las impresoras
            son compatibles con Tablets y Smartphones.
            <br>
            Lo que si es indispensable es tener una buena conexión a Internet.
          </p>

          <h3 class="mt-5 django">
            Garantía y Soporte
          </h3>
          <p class="mt-3">
            Este y cualquier producto de Castor Web esta cubierto por mi <a routerLink="/garantia-samurai">Garantía Samurái</a>.
            <br>
            Para cualquier duda sobre como funciona el soporte puedes ir a <a routerLink="/soporte">Soporte</a>.
          </p>
        </div>

      </div>

    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { SubscripcionesService } from '../../services/subscripciones.service';

@Component({
  selector: 'app-simpleshop',
  templateUrl: './simpleshop.component.html',
  styleUrls: ['./simpleshop.component.scss']
})
export class SimpleshopComponent implements OnInit {
  
  token     = '';
  username  = '';
  email     = '';

  constructor( private authService: AuthService,
               private subscripcionesService: SubscripcionesService ) { }

  ngOnInit(): void {

    if ( this.authService.estaAutenticado() ) {

      this.token    = localStorage.getItem('token');
      this.username = localStorage.getItem('username');
      this.email    = localStorage.getItem('email');

    }

  }

  subscribirme( id: string, opcion: string ): void {

    if ( !this.token ) {
      
      Swal.fire({
        icon: 'info',
        title: 'Debes de Iniciar Sesión primero',
        html: '<a class="btn btn-primary" href="login">Iniciar Sesión</a>',
        showConfirmButton: false
      });

    } else {

      Swal.fire({
        icon: 'info',
        title: '¿Deseas solicitar una subscripción de Simple Shop ' + opcion + '?',
        text: 'De ser asi, solo da click en OK y registraremos tu solicitud.'
      }).then((result) => {
        
        if ( result.isConfirmed ) {
          
          Swal.fire({
            icon: 'info',
            title: 'Un momento por favor',
            html: '<div class="row text-center animated fadeIn">'+
                      '<div class="col">'+
                          '<i class="fas fa-sync-alt fa-5x fa-spin"></i>'+
                      '</div>'+
                  '</div>',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          });

          this.subscripcionesService.solicitudSubscripcion( id, this.email, this.token ).subscribe( resp => {
            
            if ( resp['status'] === 'ok' ) {

              Swal.fire({
                icon: 'success',
                title: 'Solicitud Enviada',
                text: 'Hemos registrado tu solicitud. Un miembro de nuestro equipo se pondrá ' +
                'en contacto contigo para continuar con los detalles.'
              });

            } else {

              let texto = '';

              if ( resp['mensaje'] === 'Ya existe la solicitud' ) {
                texto = 'Ya has solicitado la subscripción de este producto. Por favor solo espera a que ' +
                'un miembro de nuestro equipo se ponga en contacto contigo.';
              } else {
                texto = 'Debes de confirmar tu correo primero antes de poder realizar esta operación.';
              }

              Swal.fire({
                icon: 'error',
                title: resp['mensaje'],
                text: texto
              });

            }

          });

        }
      });

    }

  }
  
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formaLogin: FormGroup;
  formaRegistro: FormGroup;
  formaRecover: FormGroup;
  
  cargando   = false;
  esRegistro = false;
  recover    = false;
  token      = '';
  username   = '';
  email      = '';
  
  constructor( private fb: FormBuilder,
               private authService: AuthService ) {
    this.crearFormularioLogin();
    this.crearFormularioRegistro();
    this.crearFormularioRecover();
  }
  
  ngOnInit(): void {
    
    if ( this.authService.estaAutenticado() ) {

      this.token    = localStorage.getItem('token');
      this.username = localStorage.getItem('username');
      this.email    = localStorage.getItem('email');

    }

  }

  get nombreNoValido() {
    return this.formaRegistro.get('nombre').invalid && this.formaRegistro.get('nombre').touched;
  }

  get emailNoValido() {
    return this.formaRegistro.get('email').invalid && this.formaRegistro.get('email').touched;
  }

  get passwordNoValido() {
    return this.formaRegistro.get('password').invalid && this.formaRegistro.get('password').touched;
  }

  get emailNoValidoLogin() {
    return this.formaLogin.get('email').invalid && this.formaLogin.get('email').touched;
  }

  get emailNoValidoRecover() {
    return this.formaRecover.get('email').invalid && this.formaRecover.get('email').touched;
  }

  get passwordNoValidoLogin() {
    return this.formaLogin.get('password').invalid && this.formaLogin.get('password').touched;
  }

  crearFormularioLogin() {
    
    this.formaLogin = this.fb.group({
      email   : [
        '',
        [
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
        ]
      ],
      password: ['', [ Validators.required, Validators.minLength(4) ]]
    });

  }

  crearFormularioRecover() {
    
    this.formaRecover = this.fb.group({
      email   : [
        '',
        [
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
        ]
      ]
    });

  }

  crearFormularioRegistro() {
    
    this.formaRegistro = this.fb.group({
      nombre  : ['', [ Validators.required, Validators.minLength(4) ]],
      email   : [
        '',
        [
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
        ]
      ],
      password: ['', [ Validators.required, Validators.minLength(4) ]]
    });

  }

  login() {
    
    if ( this.formaLogin.invalid ) {

      return Object.values( this.formaLogin.controls ).forEach( control => {

        if ( control instanceof FormGroup ) {

          Object.values( control.controls ).forEach( controlito => controlito.markAllAsTouched() );

        } else {
          control.markAsTouched();
        }

      });

    }
    
    this.cargando = true;

    const email    = this.formaLogin.value.email;
    const password = this.formaLogin.value.password;
    
    this.authService.login( email, password ).subscribe( resp => {
      
      if ( resp ) {
        
        window.location.reload();

      } else {
        
        this.cargando = false;

        Swal.fire({
          icon: 'error',
          title: 'Los datos ingresados son incorrectos :('
        });

      }
      
    });

    this.formaLogin.reset();

  }

  registro() {
    
    if ( this.formaRegistro.invalid ) {

      return Object.values( this.formaRegistro.controls ).forEach( control => {

        if ( control instanceof FormGroup ) {

          Object.values( control.controls ).forEach( controlito => controlito.markAllAsTouched() );

        } else {
          control.markAsTouched();
        }

      });

    }

    this.cargando = true;

    const nombre   = this.formaRegistro.value.nombre;
    const email    = this.formaRegistro.value.email;
    const password = this.formaRegistro.value.password;

    this.authService.registro( nombre, email, password ).subscribe( resp => {
      
      if ( resp['nombre'] ) {
        
        Swal.fire({
          icon: 'success',
          title: 'Bienvenido a Castor Web ' + nombre,
          text: 'Te hemos mandado un correo de confirmación a ' + email + '. Debes de ' +
          'confirmarlo antes de poder adquirir algún producto'
        }).then((result) => {
          if ( result.isConfirmed ) {
            window.location.reload();
          }
        });

      } else {
        
        this.cargando = false;
        this.esRegistro = !this.esRegistro;

        Swal.fire({
          icon: 'error',
          title: 'Ya esta registrado el correo, intenta Iniciando Sesión'
        });

      }
      
    });

    this.formaRegistro.reset();

  }

  recuperarPassword() {
    
    if ( this.formaRecover.invalid ) {

      return Object.values( this.formaRecover.controls ).forEach( control => {

        if ( control instanceof FormGroup ) {

          Object.values( control.controls ).forEach( controlito => controlito.markAllAsTouched() );

        } else {
          control.markAsTouched();
        }

      });

    }
    
    this.cargando = true;

    const email = this.formaRecover.value.email;
    
    this.authService.recuperarPassword( email ).subscribe( resp => {
      
      if ( resp['status'] === 'ok' ) {
        
        Swal.fire({
          icon: 'success',
          title: 'Revisa tu correo ' + resp['nombre'],
          text: 'Te hemos mandado un correo con tu nueva contraseña a ' + email
        }).then((result) => {
          if ( result.isConfirmed ) {
            window.location.reload();
          }
        });

      } else {
        
        this.cargando = false;

        Swal.fire({
          icon: 'error',
          title: 'El email no se encuentra registrado :('
        });

      }
      
    });

    this.formaRecover.reset();

  }

}

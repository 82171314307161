<div class="wrap-content mx-auto mb-5">
    
  <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Productos</h1>
          <p class="lead mt-4 italic">
              Una gama de productos para facilitarte la vida en tu negocio.
          </p>
      </div>
      
  </div>

  <div class="animate__animated animate__fadeIn animate__faster">
    <div class="card mt-5 mx-3" style="width: 18rem;">
        <img src="assets/img/simpleshop_product.jpg" class="card-img-top" alt="Simple Shop">
        <div class="card-body text-center">
          <h5 class="card-title"><span class="badge bg-info">Nuevo</span></h5>
          <p class="card-text">Sistema Punto de Venta para Tiendas</p>
          <a routerLink="/simpleshop" class="btn btn-outline-django">Ver más</a>
        </div>
    </div>

    <h2 class="mt-5 mx-3">Próximamente <span class="badge bg-secondary">2021</span></h2>
    <div class="card mt-5 mx-3" style="width: 18rem;">
      <img src="assets/img/pomodoroshop_product.jpg" class="card-img-top" alt="Pomodoro Shop">
      <div class="card-body text-center">
        <h5 class="card-title"><span class="badge bg-info">En Desarrollo</span></h5>
        <p class="card-text">Sistema Punto de Venta para Restaurantes</p>
        <!-- <a href="#" class="btn btn-outline-dark">Ver más</a> -->
      </div>
    </div>
  </div>

</div>
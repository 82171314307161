import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor( private http: HttpClient ) { }

  verificarEmail( email: string, codigo: string ) {
  
    const url = `${ environment.SITE_API }/app/rutas/usuario/verificar-email.php`;

    const params = new HttpParams()
        .set('api_key', environment.API_KEY)
        .set('codigo', codigo)
        .set('email', email);
    
    return this.http.get( url, { params } )
      .pipe(
        map( resp => {
          return resp;
        })
    );

  }

}
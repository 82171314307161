<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Garantía Samurái</h1>
          <p class="lead mt-4 italic">
              Basado en la forma de vida de un Samurái
          </p>
      </div>
      
  </div>

  <div class="animate__animated animate__fadeIn animate__faster">

    <div class="mt-5 mx-3">

      <h4 class="quote">
          Un Samurái nunca necesita hacer una promesa,
          <br>
          porque el solo hablar sobre (hacer) algo
          <br>
          significa lo mismo que una promesa.
      </h4>

      <p class="text-right italic mr-5 mt-4">
          - Un Castor
      </p>

    </div>

    <div class="mt-5 bg-white p-5 mx-3">

      <div class="row">

        <div class="col-md-5 col-sm-12">
          <img src="assets/img/castor_samurai.png" alt="Garantía Samurái" class="d-block mx-auto mb-5" width="285px">
        </div>

        <div class="col-md-7 col-sm-12">
          <p class="mt-5">
            Lo anterior significa que si el trabajo que realice tiene algún error de programación,
            este será corregido y no tendrás que pagarme ni un centavo puesto que desde un principio
            debió de quedar funcionando correctamente.
          </p>
        </div>

      </div>

    </div>

  </div>

</div>
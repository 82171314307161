import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-confirmar-correo',
  templateUrl: './confirmar-correo.component.html',
  styleUrls: ['./confirmar-correo.component.scss']
})
export class ConfirmarCorreoComponent implements OnInit {

  verificado: boolean;
  cargando: boolean;

  constructor( private route: ActivatedRoute,
               private usuarioService: UsuarioService ) { }

  ngOnInit(): void {

    this.cargando = true;
    
    const email  = this.route.snapshot.paramMap.get('email');
    const codigo = this.route.snapshot.paramMap.get('codigo');

    this.usuarioService.verificarEmail( email, codigo ).subscribe( resp => {
      
      if ( resp['status'] === 'ok' ) {
        this.verificado = true;
      } else {
        this.verificado = false;
      }

      this.cargando = false;

    });

  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-garantia-samurai',
  templateUrl: './garantia-samurai.component.html',
  styleUrls: ['./garantia-samurai.component.scss']
})
export class GarantiaSamuraiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

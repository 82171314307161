<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Bienvenidos</h1>
          <p class="lead mt-4 italic">
              Inicia Sesión o Regístrate como usuario
          </p>
      </div>
      
  </div>

    <div class="mt-5 mx-3 bg-white animate__animated animate__fadeIn animate__faster pb-5">

        <app-loading *ngIf="cargando" class="m-5"></app-loading>

        <div *ngIf="!cargando">

            <!-- Registro -->
            <div class="p-5 col-md-7 col-sm-12" *ngIf="esRegistro && !token && !recover">

                <h3>
                    Registro
                </h3>
                <p class="font-size-14 mt-3">
                    Podrás subscribirte a nuestros productos y hacer uso de ellos.
                    También podrás recibir promociones cuando estén disponibles.
                </p>

                <form autocomplete="off" [formGroup]="formaRegistro" (ngSubmit)="registro()">

                    <div class="form-group">
                        <input
                        type="text"
                        class="form-control"
                        formControlName="nombre"
                        [class.is-invalid]="nombreNoValido"
                        placeholder="Tu nombre">
                        <small *ngIf="nombreNoValido" class="text-danger">
                            Ingrese al menos 4 caracteres</small>
                    </div>

                    <div class="form-group mt-2">
                        <input
                        type="email"
                        class="form-control"
                        formControlName="email"
                        [class.is-invalid]="emailNoValido"
                        placeholder="Correo electrónico">
                        <small *ngIf="emailNoValido" class="text-danger">
                            Campo requerido</small>
                    </div>

                    <div class="form-group mt-2">
                        <input
                        type="password"
                        class="form-control"
                        formControlName="password"
                        [class.is-invalid]="passwordNoValido"
                        placeholder="Contraseña">
                        <small *ngIf="passwordNoValido" class="text-danger">
                            Campo requerido</small>
                    </div>

                    <!-- revisar esta parte -->
                    <!-- <div class="g-recaptcha" data-callback="recaptchaCallback" data-sitekey="6Lfr1l8UAAAAAL0vcYi2lx5ZmKf-Fz3nGAw2GgUa"></div> -->

                    <div class="d-grid d-md-block">
                        <button class="btn btn-django btn-block mt-4 float-right">Enviar</button>
                    </div>
                    <div class="clearfix"></div>

                </form>

                <a
                    class="mt-3 float-right pointer django no-underline"
                    (click)="esRegistro = !esRegistro">Ya estoy registrado</a>

            </div>

            <!-- Login -->
            <div class="p-5 col-md-7 col-sm-12" *ngIf="!esRegistro && !token && !recover">

                <h3>
                    Iniciar Sesión
                </h3>

                <form autocomplete="off" [formGroup]="formaLogin" (ngSubmit)="login()" class="mt-4">

                    <div class="form-group">
                        <input
                        type="email"
                        class="form-control"
                        formControlName="email"
                        [class.is-invalid]="emailNoValido"
                        placeholder="Correo electrónico">
                        <small *ngIf="emailNoValido" class="text-danger">
                            Campo requerido</small>
                    </div>

                    <div class="form-group mt-2">
                        <input
                        type="password"
                        class="form-control"
                        formControlName="password"
                        [class.is-invalid]="passwordNoValido"
                        placeholder="Contraseña">
                        <small *ngIf="passwordNoValido" class="text-danger">
                            Campo requerido</small>
                    </div>

                    <div class="d-grid d-md-block">
                        <button class="btn btn-django btn-block mt-4 float-right">Iniciar Sesión</button>
                    </div>
                    <div class="clearfix"></div>

                </form>

                <a
                    class="mt-3 float-left pointer django no-underline"
                    (click)="recover = !recover">Olvide mi contraseña</a>

                <a
                    class="mt-3 float-right pointer django no-underline"
                    (click)="esRegistro = !esRegistro">Aún no estoy registrado</a>

            </div>

            <!-- Recuperar contraseña -->
            <div class="p-5 col-md-7 col-sm-12" *ngIf="recover && !token">

                <h3>
                    Recupera tu contraseña
                </h3>

                <form autocomplete="off" [formGroup]="formaRecover" (ngSubmit)="recuperarPassword()" class="mt-4">

                    <div class="form-group">
                        <input
                        type="email"
                        class="form-control"
                        formControlName="email"
                        [class.is-invalid]="emailNoValidoRecover"
                        placeholder="Correo electrónico">
                        <small *ngIf="emailNoValidoRecover" class="text-danger">
                            Campo requerido</small>
                    </div>

                    <div class="d-grid d-md-block">
                        <button class="btn btn-django btn-block mt-4 float-right">Recuperar</button>
                    </div>
                    <div class="clearfix"></div>

                </form>

                <a
                    class="mt-3 float-right pointer django no-underline"
                    (click)="recover = !recover">Ya me acorde de mi contraseña</a>

            </div>

            <div class="p-5" *ngIf="token">
                <h3 class="text-center mb-4">
                    Has Iniciado Sesión {{ username }}
                </h3>
                <img
                    src="assets/img/terminator-thumbs-up-meme.jpg"
                    alt="Log In"
                    width="700px"
                    class="d-block mx-auto img-thumbnail">
            </div>

        </div>

    </div>

</div>
<footer id="footer">

    <div class="wrap-footer mb-5">
        
        <div class="mb-3 ruta-sitio">
            <a routerLink="inicio">
                <img src="favicon.ico" alt="Inicio" width="24px" class="mr-2">
            </a>
            <span class="font-weight-lighter">{{ ruta | titlecase }}</span>
        </div>        

        <div class="row mt-4">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <p>Más Información</p>
                <ul>
                    <li>
                        <a routerLink="pmc">Preguntas más comunes</a>
                    </li>
                    <li>
                        <a routerLink="politica-de-privacidad">Política de privacidad</a>
                    </li>
                    <li>
                        <a routerLink="terminos-y-condiciones">Términos y condiciones</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <p>Productos</p>
                <ul>
                    <li>
                        <a routerLink="simpleshop">Simple Shop</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <p>Soporte</p>
                <ul>
                    <li>
                        <a routerLink="soporte">Solución de problemas</a>
                    </li>
                    <li>
                        <a routerLink="servicios">Servicios</a>
                    </li>
                    <li>
                        <a routerLink="garantia-samurai">Garantía Samurái</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <p>Acerca de Castor Web</p>
                <ul>
                    <li>
                        <a routerLink="somos">Acerca de nosotros</a>
                    </li>
                    <li>
                        <a routerLink="contacto">Ponte en contacto</a>
                    </li>
                    <li>
                        <a routerLink="noticias">Noticias</a>
                    </li>
                </ul>
            </div>
        </div>
        
    </div>

    <div class="wrap-footer-text">
        <h3>Castor Web © {{ anio }} Reservados todos los derechos</h3>
    </div>

</footer>
<div class="wrap-content mx-auto mb-5">
    
    <div class="jumbotron mx-3">

      <div class="text-center pt-5 pb-5">
          <h1 class="display-4">Confirma tu correo</h1>
          <p class="lead mt-4 italic">
              ¿Eres quien dices ser o te pareces?
          </p>
      </div>
      
    </div>

    <app-loading *ngIf="cargando" class="m-5"></app-loading>
    
    <div
        *ngIf="verificado && !cargando"
        class="mt-5 bg-success bg-gradient text-white p-5 animate__animated animate__fadeIn animate__faster">

        <h3 class="text-center">
            GRACIAS POR CONFIRMAR TU CORREO
            <br><br>
            ¡BIENVENIDO A CASTOR WEB OFICIALMENTE!
        </h3>

    </div>

    <div
        *ngIf="!verificado && !cargando"
        class="mt-5 bg-danger bg-gradient text-white p-5 animate__animated animate__fadeIn animate__faster">

        <h3 class="text-center">
            LA LIGA ESTA INCORRECTA O LOS DATOS YA EXPIRARON
            <br><br>
            ¡INTENTALO DE NUEVO CON UN NUEVO REGISTRO!
        </h3>

    </div>

</div>